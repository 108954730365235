<template>
  <div>
    <Loading v-if="showLoading" />
    <Header
      title="我的團購"
      :show-member-center="false"
      :logo="meta.header_image"
    />
    <Tabs
      :tabs="tabs"
      @updateActiveTab="handleUpdateActiveTab"
      :activeTab="cureentTab"
    />
    <div class="s-p-3 order-card-list">
      <OrderCard
        v-for="groupBuyingOrder in groupBuyingOrders"
        :key="groupBuyingOrder.id"
        :group-buying-order="groupBuyingOrder"
        @exchange="showCouponModal"
      ></OrderCard>
      <p v-if="groupBuyingOrders.length === 0" class="text-center">
        {{ showLoading ? "載入中..." : "目前沒有訂單" }}
      </p>
    </div>
    <coupon-modal
      ref="couponModal"
      :coupon="selectedCoupon"
      @confirmed="refresh"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Loading from "@/components/Page/Liff/Shared/Loading";
import Header from "@/components/Page/Liff/Shared/Header";
import Tabs from "@/components/Page/Liff/Shared/Tabs";
import OrderCard from "@/components/Page/Liff/GroupBuying/Order/OrderCard";
import CoreGroupBuyingApi from "@/apis/liff/CoreGroupBuying";
import CouponModal from "@/components/Page/Liff/Coupon/CouponModal.vue";

export default {
  components: {
    Loading,
    Header,
    Tabs,
    OrderCard,
    CouponModal,
  },
  data() {
    return {
      showLoading: true,
      activeTab: "processing",
      tabs: [
        {
          id: "processing",
          text: "進行中",
        },
        {
          id: "completed",
          text: "已成團",
        },
        {
          id: "invalid",
          text: "未成團",
        },
      ],
      groupBuyingOrders: [],
      selectedCoupon: {},
    };
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
    }),
    cureentTab() {
      switch (this.$route.name) {
        case "LiffCoreGroupBuyingOrderProcessingList":
          return "processing";
        case "LiffCoreGroupBuyingOrderCompletedList":
          return "completed";
        case "LiffCoreGroupBuyingOrderInvalidList":
          return "invalid";
        default:
          return "";
      }
    },
  },
  mounted() {
    this.fetchGroupBuyingOrders(this.cureentTab);
  },
  methods: {
    async fetchGroupBuyingOrders(status) {
      this.showLoading = true;
      this.groupBuyingOrders =
        await CoreGroupBuyingApi.getInstance().getGroupBuyingOrders({ status });
      this.showLoading = false;
    },
    handleUpdateActiveTab(tabID) {
      switch (tabID) {
        case "processing":
          this.$router.push({
            name: "LiffCoreGroupBuyingOrderProcessingList",
          });
          break;
        case "completed":
          this.$router.push({
            name: "LiffCoreGroupBuyingOrderCompletedList",
          });
          break;
        case "invalid":
          this.$router.push({
            name: "LiffCoreGroupBuyingOrderInvalidList",
          });
          break;
        default:
          break;
      }
      // 即便有使用 route push 去切換 route，但是依舊是同一個元件，好像不會重新觸發 mounted hook，所以要手動去 fetch
      this.fetchGroupBuyingOrders(tabID);
    },
    showCouponModal(coupon) {
      this.selectedCoupon = coupon;
      this.$refs.couponModal.open();
    },
    refresh() {
      this.fetchGroupBuyingOrders(this.cureentTab);
    },
  },
};
</script>

<style lang="scss">
.order-card-list {
  height: calc(100vh - 56px - 52px);
  overflow-y: auto;
}
</style>
