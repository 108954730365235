<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import themeColor from "@/mixins/liff/themeColor";

export default {
  data() {
    return {
      themeConfigPage: "core_groupbuying",
    };
  },
  mixins: [themeColor],
  computed: {
    ...mapGetters({
      themeConfig: "liffGeneral/themeConfig",
    }),
  },
};
</script>

<style lang="scss">
@import "../../../../assets/css/liff/main.css";
@import "../../../../assets/css/liff/liff_init_theme_config.css";

.s-layout,
.s-layout *,
.s-layout *:before,
.s-layout *:after {
  --s-secondary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #595959;
  --s-gray: #909399;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #ecedef;
}

.s-layout {
  color: var(--s-gray-dark);
  background-color: var(--s-gray-lighter);
}
</style>
