<template>
  <div class="order-card">
    <div class="order-card__image">
      <img :src="product.images[0].pic_url" />
    </div>
    <div class="order-card__content">
      <div class="order-card__date">{{ `${startDate} ~ ${endDate}` }}</div>
      <div class="order-card__title">{{ product.title }}</div>
      <div class="order-card__subtitle">{{ sku.sku_name }}</div>
      <div class="order-card__created-at">跟團時間：{{ createdAt }}</div>
      <div class="order-card__button">
        <button
          v-if="coupon"
          class="s-btn s-btn-outline-primary s-btn-xs btn-exchange"
          @click="handleExchange"
          type="button"
          :disabled="buttonText != '查看優惠券'"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { format, isWithinInterval, isAfter, isBefore } from "date-fns";

export default {
  props: {
    groupBuyingOrder: {
      type: Object,
    },
  },
  computed: {
    groupBuying() {
      return this.groupBuyingOrder.groupbuying
    },
    startDate() {
      return this.groupBuying ? format(new Date(this.groupBuying.start_at), 'yyyy-MM-dd') : null
    },
    endDate() {
      return this.groupBuying ? format(new Date(this.groupBuying.end_at), 'yyyy-MM-dd') : null
    },
    createdAt() {
      return format(new Date(this.groupBuyingOrder.created_at), 'yyyy-MM-dd HH:MM')
    },
    sku() {
      return this.groupBuyingOrder?.sku
    },
    product() {
      return this.sku?.product
    },
    coupon() {
      return this.groupBuyingOrder?.redeem_code
    },
    buttonText() {
      if (this.groupBuyingOrder.redeem_code) {
        const redeemCode = this.groupBuyingOrder.redeem_code
         if (!redeemCode) {
          return "";
        }
        if (!redeemCode.redeem_at) {
          return this.checkTimeAndGetText(redeemCode.redeem_code_bundle)
        }
        if (redeemCode.redeem_at) {
          return "優惠券已使用"
        }
        return "優惠券無法使用"
      }
      return "";
    }
  },
  methods: {
    handleExchange() {
      this.$emit("exchange", this.coupon);
    },
    checkTimeAndGetText(data) {
      if (isWithinInterval(new Date(), { start: new Date(data.start_at), end: new Date(data.end_at)})) {
        return "查看優惠券"
      }
      if (isAfter(new Date(), new Date(data.end_at))) {
        return "優惠券已過期"
      }

      if (isBefore(new Date, new Date(data.start_at))) {
        return "優惠券尚未開放"
      }
      return "";
    }
  }
};
</script>

<style lang="scss">
.order-card {
  display: flex;
  background: white;
  border: 1px solid #e5e5ea;
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;

  .order-card__image > img {
    width: 130px;
    height: 130px;
  }

  .order-card__content {
    padding: 12px;
    width: 100%;
  }

  &__date {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #979797;
    margin-bottom: 8px;
    font-family: "PingFang TC";
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #2c2c2e;
    margin-bottom: 8px;
  }

  &__subtitle, &__created-at {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.408px;
    color: #2c2c2e;
    margin-bottom: 6px;
  }

  &__button {
    button {
      font-size: 13px;
      line-height: 18px;
      padding: 2px 8px;
      font-weight: 600;
      width: auto;
      float: right;
    }

    .btn-not-enough {
      background: #e5e5ea;
      border: 1px solid #c0c4cc;
      color: #2c2c2e;
      cursor: not-allowed;
    }
  }
}
</style>
