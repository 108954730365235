<template>
  <div>
    <Header
      title="團購一覽"
      :show-member-center="false"
      :logo="meta.header_image"
    />
    <div class="s-p-3">
      <HorizontalProductCard
        v-for="groupBuying in groupBuyings"
        :key="groupBuying.id"
        :group-buying="groupBuying"
      ></HorizontalProductCard>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Page/Liff/Shared/Header";
import HorizontalProductCard from "@/components/Page/Liff/GroupBuying/Products/HorizontalProductCard";
import CoreGroupBuyingApi from "@/apis/liff/CoreGroupBuying";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    HorizontalProductCard,
  },
  data() {
    return {
      groupBuyings: [],
    };
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
    }),
  },
  mounted() {
    this.fetchgroupBuyings();
  },
  methods: {
    async fetchgroupBuyings() {
      this.groupBuyings =
        await CoreGroupBuyingApi.getInstance().getGroupBuyings();
    },
  },
};
</script>

<style lang="scss"></style>
