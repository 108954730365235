<template>
  <div class="horizontal-product-card">
    <div class="horizontal-product-card__image">
      <img :src="groupBuying.product.images[0].pic_url" />
    </div>
    <div class="horizontal-product-card__content">
      <div class="horizontal-product-card__date">{{ `${startDate}-${endDate}` }}</div>
      <div class="horizontal-product-card__title">{{ groupBuying.product.title }}</div>
      <div class="horizontal-product-card__subtitle">{{ groupBuying.sku.sku_name }}</div>
      <div class="horizontal-product-card__bottom s-flex s-groupBuyings-center s-justify-end">
        <div class="horizontal-product-card__button">
          <button
            class="s-btn s-btn-outline-primary s-btn-xs btn-exchange"
            type="button"
            @click="showGroupBuying(groupBuying.id)"
          >
            查看
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";

export default {
  props: {
    groupBuying: {
      type: Object,
    },
  },
  computed: {
    startDate() {
      return format(new Date(this.groupBuying.start_at), 'yyyy-MM-dd');
    },
    endDate() {
      return format(new Date(this.groupBuying.end_at), 'yyyy-MM-dd');
    },
  },
  methods: {
    showGroupBuying(id) {
      this.$router.push({
        name: 'LiffCoreGroupBuyingShow',
        params: {
          id,
        }
      })
    },
  },
};
</script>

<style lang="scss">
.horizontal-product-card {
  display: flex;
  background: white;
  border: 1px solid #e5e5ea;
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;

  .horizontal-product-card__image > img {
    width: 120px;
    height: 120px;
  }

  .horizontal-product-card__content {
    padding: 12px;
    width: 100%;
  }

  &__date {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #979797;
    margin-bottom: 8px;
    font-family: "PingFang TC";
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #2c2c2e;
    margin-bottom: 8px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.408px;
    color: #2c2c2e;
    margin-bottom: 6px;
  }

  &__button {
    button {
      font-size: 13px;
      line-height: 18px;
      padding: 6px 16px;
      font-weight: 600;
    }

    .btn-not-enough {
      background: #e5e5ea;
      border: 1px solid #c0c4cc;
      color: #2c2c2e;
      cursor: not-allowed;
    }
  }
}
</style>
